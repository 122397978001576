var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "text-2xl font-bold text-green-700"
  }, [_vm._v(" " + _vm._s(_vm.$t("status")) + " ")])])]), _c('div', [_c('table', {
    staticClass: "min-w-full"
  }, [_c('thead', [_c('tr', [_vm._l(_vm.headers, function (item, key) {
    return _c('th', {
      key: key,
      staticClass: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t(item)) + " ")]);
  }), _c('th', {
    staticClass: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  })], 2)]), _c('tbody', {
    staticClass: "bg-white"
  }, _vm._l(_vm.content, function (item, index) {
    return _c('tr', {
      key: index
    }, [_vm._l(_vm.headers, function (it, ind) {
      return _c('td', {
        key: ind,
        staticClass: "px-6 py-4 whitespace-no-wrap border-b border-gray-200"
      }, [it == 'color' && _vm.name == 'status' ? _c('div', [_c('p', [_c('label', {
        staticClass: "inline-flex items-center mt-3"
      }, [_c('div', {
        staticClass: "rounded-full h-5 w-5 flex items-center justify-center",
        style: {
          background: _vm.content[index][it]
        }
      })])])]) : _c('div', {
        staticClass: "text-sm capitalize leading-5 text-gray-900"
      }, [_vm._v(" " + _vm._s(_vm.content[index][it]) + " ")])]);
    }), _vm.name != 'productsOrder' ? _c('td', {
      staticClass: "px-6 py-4 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "flex justify-end"
    }, [_vm.getAccess(_vm.name, 'update') && _vm.name != 'statusHistories' ? _c('router-link', {
      staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3 mt-1",
      class: _vm.$colors.actionTable,
      attrs: {
        "to": "".concat(_vm.name, "/edit/").concat(_vm.content[index]['_id'])
      }
    }, [_c('i', {
      staticClass: "material-icons mx-2"
    }, [_vm._v("edit")])]) : _vm._e(), _c('button', {
      staticClass: "justify-end",
      on: {
        "click": function click($event) {
          return _vm.remove(_vm.content[index]['_id']);
        }
      }
    }, [_c('i', {
      staticClass: "material-icons mx-2"
    }, [_vm._v("delete")])])], 1)]) : _vm._e()], 2);
  }), 0)]), _vm.content.length <= 0 ? _c('div', {
    staticClass: "bg-blue-100 py-8"
  }, [_c('span', {
    staticClass: "w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-green-500 whitespace-no-wrap"
  }, [_c('i', {
    staticClass: "material-icons mx-3"
  }, [_vm._v("error")]), _c('span', {}, [_vm._v(_vm._s(_vm.$t("no_rows_selected")) + " ")])])])]) : _vm._e()]), _c('div', {
    staticClass: "p-1"
  }, [_c('div', {
    staticClass: "flex flex-col items-center my-12"
  }, [_vm.paginate.lastpage > 1 ? _c('div', {
    staticClass: "flex text-gray-700"
  }, [_vm.paginate.currentpage > 1 ? _c('button', {
    staticClass: "h-8 w-8 focus:outline-none mr-6 flex justify-center text-green-700 items-center rounded-full bg-gray-200 hover:bg-green-600 hover:text-white cursor-pointer",
    attrs: {
      "disabled": _vm.paginate.currentpage == 1
    },
    on: {
      "click": _vm.prev
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v(" keyboard_arrow_left ")])]) : _vm._e(), _c('div', {
    staticClass: "flex h-8 font-medium rounded-full bg-gray-200"
  }, [_vm.paginate.currentpage != _vm.paginate.lastpage ? _c('div', {
    staticClass: "w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full bg-green-600 text-white"
  }, [_vm._v(" " + _vm._s(_vm.paginate.currentpage) + " ")]) : _c('div', {
    staticClass: "w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
  }, [_vm.paginate.currentpage != 1 ? _c('span', [_vm._v(" 1 ")]) : _vm._e()]), _vm.paginate.currentpage >= 1 && _vm.paginate.lastpage != 1 ? _c('div', {
    staticClass: "w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
  }, [_vm._v(" ... ")]) : _vm._e(), _c('button', {
    staticClass: "w-8 md:flex justify-center items-center hover:bg-green-600 hover:text-white focus:outline-none hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full",
    class: _vm.paginate.currentpage == _vm.paginate.lastpage ? 'bg-green-600  text-white' : ' '
  }, [_vm._v(" " + _vm._s(_vm.paginate.lastpage) + " ")])]), _vm.paginate.currentpage < _vm.paginate.lastpage ? _c('button', {
    staticClass: "h-8 w-8 ml-6 focus:outline-none flex justify-center text-green-700 items-center rounded-full bg-gray-200 hover:bg-green-600 hover:text-white cursor-pointer",
    attrs: {
      "disabled": _vm.paginate.currentpage == _vm.paginate.lastpage
    },
    on: {
      "click": _vm.next
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v(" keyboard_arrow_right ")])]) : _vm._e()]) : _vm._e()])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }